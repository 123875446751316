import React from 'react';
import styled from 'styled-components';
import StarIcon from '@mui/icons-material/Star';
import Client_Photo_1 from '../../assets/EasyPC_pfp.png'; // Fallback profile image
import Client_Photo_2 from '../../assets/Testimonial_2.png'; // Fallback profile image

/* Variation #2.3: Purple to Black Gradient, White Cards with Subtle Shadow */

const TestimonialsSection = styled.section`
  padding: 60px 32px;
  background: linear-gradient(135deg, #6a11cb, #000);
  text-align: center;
  color: #fff;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 50px;
  color: #fff;
`;

const TestimonialContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
`;

const TestimonialCard = styled.div`
  background-color: #fff;
  color: #333;
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  padding: 25px;
  max-width: 450px;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  border-top: 4px solid #fff;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.4);
  }
`;

const CenteredContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Stars = styled.div`
  color: #FFD700;
  margin-bottom: 10px;

  svg {
    font-size: 2rem;
  }
`;

const TestimonialText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin: 0;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #ddd;
  margin: 20px 0;
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  background-color: #eee;
  margin-bottom: 10px;
`;

const Name = styled.h3`
  font-size: 1.1rem;
  margin-bottom: 5px;
  color: #333;
`;

const Role = styled.p`
  color: #666;
  font-size: 0.9rem;
  margin: 0;
`;


const Testimonials = () => (
  <TestimonialsSection>
    <SectionTitle>What Our Clients Say</SectionTitle>
    <TestimonialContainer>
      {/* Variation 1: With accent border */}
      <TestimonialCard style={{ borderTop: '4px solid #6a11cb' }}>
        <CenteredContent>
          <Stars>
            {[...Array(5)].map((_, i) => <StarIcon key={i} />)}
          </Stars>
          <TestimonialText>
            "The AndyAI team is easy to work and collaborate with. We’re working together to solve problems and scale solutions through AI. At present, we’re working closely with each other."
          </TestimonialText>
        </CenteredContent>
        <Divider />
        <ProfileInfo>
          <ProfileImage src={Client_Photo_1} alt="Tom Howard" />
          <Name>Alison Morada DelaCruz</Name>
          <Role>Head of Operations, EasyPC</Role>
        </ProfileInfo>
      </TestimonialCard>

      {/* Variation 2: Without accent border */}
      <TestimonialCard style={{ borderTop: '4px solid #6a11cb' }}>
        <CenteredContent>
          <Stars>
            {[...Array(5)].map((_, i) => <StarIcon key={i} />)}
          </Stars>
          <TestimonialText>
            "AndyAI has transformed our medical supply e-commerce's customer support. Response time has increased by 900%, and sales have grown by 200% through our official Messenger account. Less work, more sales through the AI agent."
          </TestimonialText>
        </CenteredContent>
        <Divider />
        <ProfileInfo>
          <ProfileImage src={Client_Photo_2} alt="Sarah Mitchell" />
          <Name>Erwin Valdepena</Name>
          <Role>Customer Support Lead, MedmartPH</Role>
        </ProfileInfo>
      </TestimonialCard>
    </TestimonialContainer>
  </TestimonialsSection>
);

export default Testimonials;
