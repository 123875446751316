import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MainRecording from '../../assets/Hero_Video_1.webm';
import ClientLogosSection from '../toolbox/ClientLogosSection';
import Testimonials from '../toolbox/TestimonialsSection';
import FeaturesSection from '../toolbox/FeaturesSection';
import CTAForm from '../toolbox/CtaFormSection';

const HeroSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 48px calc(50vw - 624px); /* dynamic padding for large screens */
  background: white;
  color: #333;
  min-height: 80vh;

  @media (max-width: 1440px) {
    padding: 48px 64px;
  }

  @media (max-width: 1024px) {
    padding: 48px 32px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 40px 20px;
  }
`;

const TextContent = styled.div`
  flex: 1;
  max-width: 600px;
  background: radial-gradient(circle at top left, rgba(106, 17, 203, 0.06), white 70%);
  padding-right: 20px;

  @media (max-width: 768px) {
    padding-right: 0;
    text-align: center;
    margin-bottom: 30px;
  }
`;

// Improved SubHeader component with additional padding, margin, and highlight
const SubHeader = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  margin: 40px 0;
  padding: 10px 20px;
  display: inline-block;
  background: linear-gradient(90deg, #6a11cb, #8c61bb);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 2px solid transparent;
  border-image: linear-gradient(90deg, #6a11cb, #8c61bb) 1;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(106, 17, 203, 0.2);
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin: 30px 0;
    padding: 8px 16px;
  }
`;

const VideoContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  video {
    width: 100%;
    max-width: 550px;
    border-radius: 16px;
  }
`;

const DynamicText = styled.span`
  color: ${({ color }) => color};
  font-weight: 700;
`;

const CTAContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 25px;

  @media (max-width: 768px) {
    justify-content: center;
    flex-direction: column;
  }
`;

const CTAButton = styled.button`
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  border: none;
  background-color: #6a11cb;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #4e0aa5;
  }
`;

const TextLink = styled.a`
  font-size: 1rem;
  font-weight: 600;
  color: #6a11cb;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:after {
    content: ' →';
    transition: transform 0.2s;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const words = [
  { text: 'FASTEST ', color: '#ff5722' },
  { text: 'PROVEN ', color: '#009688' },
  { text: 'TRUSTED ', color: '#6a11cb' },
];

const LandingPage = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((index) => (index + 1) % words.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const { text: currentWord, color: currentColor } = words[currentWordIndex];

  return (
    <>
      <HeroSection>
        <TextContent>
          <h1 style={{ fontSize: '3rem', lineHeight: '1.15', marginBottom: '20px' }}>
            The <DynamicText color={currentColor}>{currentWord}</DynamicText>  
            Customer Service AI is <DynamicText color="#6a11cb">AndyAI</DynamicText>.
          </h1>
          <SubHeader>Grow Sales by Up to 21X!</SubHeader>
          <p style={{ fontSize: '1.2rem', color: '#666' }}>
            Are you having a problem with customer service and closing more sales? What if AI agents can reply faster, better and nonstop? What if you can grow your sales more? Learn how AI can work for you too!
          </p>
          <CTAContainer>
            <CTAButton>TRY FOR FREE</CTAButton>
            <TextLink href="#">Get the FREE "How AI Can Grow Your Sales"
            </TextLink>
          </CTAContainer>
        </TextContent>
        <VideoContent>
          <video autoPlay loop muted playsInline>
            <source src={MainRecording} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </VideoContent>
      </HeroSection>

      <ClientLogosSection />
      <FeaturesSection />
      <Testimonials />
      <CTAForm />
    </>
  );
};

export default LandingPage;
