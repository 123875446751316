import React from 'react';
import styled from 'styled-components';
import Features_1 from '../../assets/Features_1.png';
import Features_2 from '../../assets/Features_2.png';
import Features_3 from '../../assets/Features_3.png';

const FeaturesContainer = styled.section`
  width: 100%;
  padding: 60px 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

const FeatureRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  padding: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 30px;
  }
`;

const TextColumn = styled.div`
  flex: 1;
  padding: 0 20px;
  @media (max-width: 768px) {
    text-align: center;
    padding: 0;
  }
`;

const FeatureHeading = styled.h3`
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 15px;
  color: #333;
`;

const FeatureSubtext = styled.p`
  font-size: 1.1rem;
  color: #666;
  line-height: 1.5;
`;

const ImageColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0 20px;
`;

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 900px;
  background-color: rgb(239, 239, 248);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px; /* some space around the image */

  img {
    width: 100%;
    max-width: 900px;
    height: auto;
    border-radius: 12px; /* optional subtle rounding for the image itself */
  }

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 10px;
  }
`;

const FeaturesSection = () => {
  return (
<FeaturesContainer>
  {/* Row 1: Text Left, Image Right */}
  <FeatureRow>
    <TextColumn>
      <FeatureHeading>Centralized AI Agent Management</FeatureHeading>
      <FeatureSubtext>
        Manage and monitor all your AI agents effortlessly from one intuitive dashboard. Instantly deploy, track, and optimize performance, keeping your team efficient and your operations streamlined.
      </FeatureSubtext>
    </TextColumn>
    <ImageColumn>
      <ImageWrapper>
        <img src={Features_1} alt="Centralized AI Agent Management" />
      </ImageWrapper>
    </ImageColumn>
  </FeatureRow>

  {/* Row 2: Image Left, Text Right */}
  <FeatureRow reverse>
    <TextColumn>
      <FeatureHeading>Insightful Real-Time Metrics</FeatureHeading>
      <FeatureSubtext>
        Stay informed with comprehensive real-time analytics. From customer engagement to operational status, gain clarity on key metrics to make smarter, data-driven decisions instantly.
      </FeatureSubtext>
    </TextColumn>
    <ImageColumn>
      <ImageWrapper>
        <img src={Features_2} alt="Insightful Real-Time Metrics" />
      </ImageWrapper>
    </ImageColumn>
  </FeatureRow>

  {/* Row 3: Text Left, Image Right */}
  <FeatureRow>
    <TextColumn>
      <FeatureHeading>Detailed AI Chat Logs</FeatureHeading>
      <FeatureSubtext>
        Track every conversation your AI agents handle with detailed logs. Understand customer interactions deeply, fine-tune agent responses, and continuously improve customer satisfaction and sales outcomes.
      </FeatureSubtext>
    </TextColumn>
    <ImageColumn>
      <ImageWrapper>
        <img src={Features_3} alt="Detailed AI Chat Logs" />
      </ImageWrapper>
    </ImageColumn>
  </FeatureRow>
</FeaturesContainer>
  );
};

export default FeaturesSection;
