import React, { useState } from 'react';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';
import { FaArrowRight, FaTimes } from 'react-icons/fa';

// Global Styles
const GlobalStyles = styled.div`
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    height: 100%;
  }
  body {
    font-family: Arial, sans-serif;
    scroll-behavior: smooth;
  }
`;

// Fixed container for both the top banner and header
const FixedHeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1100; /* High z-index to stay above other content */
  display: flex;
  flex-direction: column;
`;

// Top banner (fixed height on desktop; auto on mobile)
const TopBannerContainer = styled.div`
  width: 100%;
  height: 50px;
  background-color: rgb(110,31,147);
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 10px 20px;
    align-items: center;
  }
`;

const CenterText = styled.div`
  font-size: 0.9rem;
  text-align: center;
  width: 100%;
`;

const RightActions = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  gap: 15px;
  
  @media (max-width: 768px) {
    position: static;
    transform: none;
    margin-top: 10px;
  }
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const DismissButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
`;

const PageWrapper = styled.div`
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Content wrapper with top margin to offset the fixed header
// If banner is visible, margin-top = banner height (50px) + header height (80px) = 130px; otherwise, 80px.
const ContentWrapper = styled.div`
  flex: 1;
  margin-top: ${({ fixedHeight }) => fixedHeight}px;
`;

const PublicLayout = ({ children }) => {
  // State for top banner dismissal
  const [showTopBanner, setShowTopBanner] = useState(true);
  // Lift burger state so we can hide the banner when the mobile menu is open
  const [burgerOpen, setBurgerOpen] = useState(false);

  const bannerHeight = showTopBanner ? 50 : 0;
  const headerHeight = 80; // Fixed header height
  const fixedHeight = bannerHeight + headerHeight;

  return (
    <PageWrapper>
      <GlobalStyles />
      <FixedHeaderWrapper>
        {/* Only show the top banner if it hasn't been dismissed and the burger menu is closed */}
        {showTopBanner && !burgerOpen && (
          <TopBannerContainer>
            <CenterText>
              Discover AndyAI – Your AI-powered enterprise companion! Join our waitlist now!
            </CenterText>
            <RightActions>
              <ActionButton onClick={() => { /* Optional: add Learn More action */ }}>
                Learn More <FaArrowRight />
              </ActionButton>
              <DismissButton onClick={() => setShowTopBanner(false)}>
                <FaTimes />
              </DismissButton>
            </RightActions>
          </TopBannerContainer>
        )}
        <Header
          headerHeight={headerHeight}
          burgerOpen={burgerOpen}
          setBurgerOpen={setBurgerOpen}
        />
      </FixedHeaderWrapper>
      <ContentWrapper fixedHeight={fixedHeight}>
        <Outlet />
        {children}
      </ContentWrapper>
      <Footer />
    </PageWrapper>
  );
};

export default PublicLayout;
