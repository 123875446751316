import React from 'react';
import styled from 'styled-components';
import { FaBars, FaTimes } from 'react-icons/fa';

// Define nav accent color from the theme range
const navAccentColor = 'rgb(140,61,187)';

/* --- Main Navigation Bar Section --- */
const HeaderWrapper = styled.header`
  height: 80px;
  background: white;
  padding: 0 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  @media (max-width: 768px) {
    padding: 0 30px;
  }
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const Logo = styled.a`
  font-size: 24px;
  font-weight: 700;
  color: ${navAccentColor};
  text-decoration: none;
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 30px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled.a`
  color: #333;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${navAccentColor};
  }
`;

const RightSection = styled.div`
  display: flex;
  gap: 15px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const BurgerIcon = styled.div`
  font-size: 24px;
  cursor: pointer;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

/* --- Mobile Menu with Fixed Positioning --- */
const MobileMenu = styled.div`
  position: fixed;
  top: 80px; /* Starts right below the header */
  right: 0;
  width: 100%;
  height: calc(100% - 80px); /* Covers the rest of the viewport */
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  z-index: 1200; /* Ensure it overlays content and the header */
`;

const MobileNavLink = styled.a`
  color: #333;
  font-size: 18px;
  margin-bottom: 30px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${navAccentColor};
  }
`;

const MobileButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const LoginButton = styled.a`
  padding: 10px 20px;
  font-size: 1rem;
  color: ${navAccentColor};
  text-decoration: none;
  border: 1px solid ${navAccentColor};
  border-radius: 5px;
  transition: all 0.3s ease;
  &:hover {
    background-color: ${navAccentColor};
    color: white;
  }
`;

const SignupButton = styled.a`
  padding: 10px 20px;
  font-size: 1rem;
  background-color: ${navAccentColor};
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: rgb(130,50,177);
  }
`;

const Header = ({ headerHeight, burgerOpen, setBurgerOpen }) => {
  // Smooth scroll to section
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      // Close the burger menu after navigation
      setBurgerOpen(false);
    }
  };

  return (
    <>
      <HeaderWrapper>
        <LeftSection>
          <Logo href="/">AndyAI</Logo>
          <NavLinks>
            <NavLink onClick={() => scrollToSection('features')}>Features</NavLink>
            <NavLink onClick={() => scrollToSection('use-cases')}>Use Cases</NavLink>
            <NavLink onClick={() => scrollToSection('testimonials')}>Testimonials</NavLink>
          </NavLinks>
        </LeftSection>

        <RightSection>
          <LoginButton href="/login">LOG IN</LoginButton>
          <LoginButton href="/signup">SIGN UP</LoginButton>
          <SignupButton href="/signup">BOOK A CALL</SignupButton>
        </RightSection>

        <BurgerIcon onClick={() => setBurgerOpen(!burgerOpen)}>
          {burgerOpen ? <FaTimes /> : <FaBars />}
        </BurgerIcon>
      </HeaderWrapper>

      {/* Mobile Menu */}
      <MobileMenu open={burgerOpen}>
        <MobileNavLink onClick={() => scrollToSection('features')}>Features</MobileNavLink>
        <MobileNavLink onClick={() => scrollToSection('pricing')}>Pricing</MobileNavLink>
        <MobileNavLink onClick={() => scrollToSection('about')}>About</MobileNavLink>
        <MobileButtonWrapper>
        <LoginButton href="/login">LOG IN</LoginButton>
          <LoginButton href="/signup">SIGN UP</LoginButton>
          <SignupButton href="/signup">BOOK A CALL</SignupButton>
        </MobileButtonWrapper>
      </MobileMenu>
    </>
  );
};

export default Header;
