import React, { useState } from 'react';
import styled from 'styled-components';

const FormSection = styled.section`
  background: #FFFFFF;
  color: #333;
  padding: 80px calc(50vw - 500px);
  text-align: center;
  
  @media (max-width: 768px) {
    padding: 60px 20px;
  }
`;

const FormHeading = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 16px;
  color: #6a11cb; /* Soft purple */
`;

const FormSubtext = styled.p`
  font-size: 1.2rem;
  margin-bottom: 40px;
  opacity: 0.9;
`;

const StyledForm = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const InputField = styled.input`
  padding: 12px 15px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 1rem;
  background: #fff;
  color: #333;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  &::placeholder {
    color: #999;
  }
`;

const SubmitButton = styled.button`
  grid-column: span 2;
  padding: 15px;
  background-color: #6a11cb;
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 1.2rem;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #5e0eb3;
  }
  @media (max-width: 768px) {
    grid-column: span 1;
  }
`;


const CTAForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    mobile: '',
    company: '',
    schedule: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.email.includes('@')) {
      alert('Please enter a valid email.');
      return;
    }

    // TODO: Implement form submission to backend API when ready
    console.log('Form submitted:', formData);
  };

  return (
    <FormSection>
      <FormHeading>Ready to Generate Up to 21X More Sales?</FormHeading>
      <FormSubtext>Book a FREE call today and discover how AndyAI can transform your customer interactions and skyrocket your sales!</FormSubtext>

      <StyledForm onSubmit={handleSubmit}>
        <InputField
          name="fullName"
          placeholder="Full Name"
          value={formData.fullName}
          onChange={handleChange}
          required
        />

        <InputField
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <InputField
          name="mobile"
          placeholder="Mobile #"
          value={formData.mobile}
          onChange={handleChange}
        />

        <InputField
          name="company"
          placeholder="Company"
          value={formData.company}
          onChange={handleChange}
        />

        <InputField
          name="schedule"
          placeholder="Preferred Schedule (e.g., March 29, 2PM)"
          value={formData.schedule}
          onChange={handleChange}
        />

        <SubmitButton type="submit">BOOK A FREE CALL</SubmitButton>
      </StyledForm>
    </FormSection>
  );
};

export default CTAForm;
