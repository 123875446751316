import React from 'react';
import styled, { keyframes } from 'styled-components';

// Import client logos (all JPEG files)
import client_logo_1 from '../../assets/client_logo_1.png';
import client_logo_2 from '../../assets/client_logo_2.png';
import client_logo_3 from '../../assets/client_logo_3.png';
import client_logo_4 from '../../assets/client_logo_4.png';
import client_logo_5 from '../../assets/client_logo_5.png';
import client_logo_6 from '../../assets/client_logo_6.png';
import client_logo_7 from '../../assets/client_logo_7.png';
import client_logo_8 from '../../assets/client_logo_8.png';
import client_logo_9 from '../../assets/client_logo_9.png';

// Array of logos
const logos = [
  client_logo_1,
  client_logo_2,
  client_logo_3,
  client_logo_4,
  client_logo_5,
  client_logo_6,
  client_logo_7,
  client_logo_8,
  client_logo_9,
];

// Keyframes for continuous scroll effect
const scroll = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`;

// Main container for the section
const ClientSection = styled.section`
  width: 100%;
  height: 64vh; 
  background: linear-gradient(135deg, #000, rgb(110, 31, 147));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// Main heading text
const PrimaryText = styled.div`
  color: white;
  font-size: 4.8vh; 
  width: 60%;
  text-align: center;
  margin-bottom: 8vh;

  /* Slightly reduce font size on very narrow screens */
  @media (max-width: 800px) {
    font-size: 4.8vh;
    margin-bottom: 6vh;
  }
`;

// Container that crops the scrolling logos
const LogosContainer = styled.div`
  width: 80%;
  overflow: hidden;
`;

// Continuous scrolling container
const LogosSlider = styled.div`
  display: flex;
  align-items: center;
  animation: ${scroll} 30s linear infinite;
`;

/*
  The default desktop logo width is 15%. 
  On narrow screens (i.e., max-width: 375px), we increase 
  that to ~18% to appear visually bigger without crowding.
*/
const LogoImage = styled.img`
  width: 15%;
  margin-right: 2%;
  filter: grayscale(100%) brightness(0) invert(1);

  @media (max-width: 800px) {
    width: 25%;       /* Slightly bigger on small phones */
    margin-right: 3%; /* Increase spacing so logos aren't jammed together */
  }
`;

const ClientLogosSection = () => {
  // Duplicate array for seamless scrolling
  const allLogos = [...logos, ...logos];

  return (
    <ClientSection>
      <PrimaryText>
        Trusted by Startups and Industry Leaders Alike
      </PrimaryText>
      <LogosContainer>
        <LogosSlider>
          {allLogos.map((logo, index) => (
            <LogoImage src={logo} alt={`Client logo ${index + 1}`} key={index} />
          ))}
        </LogosSlider>
      </LogosContainer>
    </ClientSection>
  );
};

export default ClientLogosSection;
